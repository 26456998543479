/*Variables*/
$mba-background: #242122;
$tw-red: #bd0000;
$v-light-gray: #f9f8f8; /*GENERIC*/

li,
ol,
p,
ul {
  font-size: 1.2rem;
  color: $black;
}

ul {
  list-style-type: none;
}

li::before {
  content: "•";
  color: $tw-red;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

img {
  border: 4px solid $white;
  margin-top: 15px;
  margin-bottom: 15px;
}

//Card
.card {
  max-width: 300px;
  margin-left:auto;
  margin-right:auto;

  img {
    border: 0;
    margin: 0;
  }
}

.card-section p {
  text-align: center;
}

.divider-short {
  span {
    display: block;
    background-color: $tw-red;
    height: 3px;
    width: 80px;
    margin: 0 auto 40px;
  }
}

.centered {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.button {
  border-radius: 8px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.header {
  background-color: $mba-background;
  min-height: 200px;
  background-position: bottom;
  background-repeat: no-repeat;
  border-bottom: 1px solid $dark-gray;
  background-size: cover;

  .logo-container {
    margin: 5px auto 10px;
    text-align: center;
  }

  .logo {
    border: 0;
  }

  h1,
  h2 {
    color: $white;
    font-weight: 700;
    text-align: center;
  }
}

.intro {
  margin-top: 20px;
  margin-bottom: 20px;

  img {
    margin-top: 15px;
  }
}

[data-whatinput='mouse'] .button,
*:focus {
  outline: 2px dashed $tw-red;
  outline-offset: 4px;
}

h3 {
  margin-top: 35px;
}

.banner{
  background-color: $primary-color;
  padding-top: 0!important;
  padding-bottom: 0!important;

  h3{
    color: $white;
    display: inline-block;
    margin-left:15px;
    margin-top:0;
  }
  img{
    display: inline-block;
  }
}

.section {
  &.gray {
    border-top: 1px solid $dark-gray;
    border-bottom: 1px solid $dark-gray;
    background-color: $v-light-gray;
  }
}

h2.headline {
  font-size: 2.6rem;
}

h3.headline{
  margin-top:0;
}

.headline {
  text-align: center;
  padding: 20px 0 10px;
  font-weight: 200;
}


footer {
  padding-top: 40px;
  background-color: lighten($medium-gray, 10%);

  a,
  p {
    font-size: rem-calc(16);
    color: #111;
    text-align: center;
    font-weight: 300;
  }

  *:focus {
    outline: 2px dashed $tw-red;
    outline-offset: 4px;
    color: #111;
  }
}
@include breakpoint(medium) {
  .header {
    max-height: 300px;

    h1 {
      padding-top: 40px;
    }
  }

  .intro {
    img {
      margin-top: -15px;
    }
  }

  .more-info {
    margin-top: 55px;
  }

  .section {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .intro {
    padding-top: 0;
  }
}
@include breakpoint(large) {
  .header {
    padding-top: 20px;
    min-height: 300px;

    h1 {
      padding-top: 40px;
    }
  }
}
